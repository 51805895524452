import theme from "../theme";

const screenWidth = 1600;

// fonrFamilySeparator is used in theme
// so intentionally using hoisting here
function fontFamilySeparator(families: string | undefined) {
  if (!families) {
    return "";
  }
  return families
    .split(",")
    .map((name) => `\"${name}\"`)
    .join(",");
}

const ptToRemConverter = (pt: number) => {
  const fontSize = theme.typography.fontSize;
  return ((pt / fontSize) * 4) / 3;
};

const ptToVwConverter = (pt: number) => {
  return (pt / screenWidth) * 100;
};

const mobileVwConverter = (pt: number) => {
  return (pt / 750) * 100;
};

const convertDate = (param: string | undefined) => {
  if (!param) return;

  const date = new Date(param);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}.${month}.${day}`;
};

const parseInfo = (info: string | undefined) => {
  if (!info) return info;
  const stringified = JSON.stringify(info);
  const original =
    "<div>" + stringified.slice(1, stringified.length - 1) + "</div>";
  let result = "";
  for (let i = 0; i < original.length; i++) {
    if (!original[i + 1] === undefined) {
      break;
    }
    const two = original[i] + original[i + 1];
    if (two === "\\n") {
      result += "<br/>";
      i++;
      continue;
    } else if (original[i] === "\\") {
      continue;
    } else {
      result += original[i];
    }
  }
  return result;
};

const swapId = (id: string, index: number) => {
  return `${id.slice(0, -1)}${index}`;
};

const filterId = (array: any[]) => {
  return array.map((category, i) => {
    return {
      ...category,
      id: swapId(category.id, i),
    };
  });
};

const scrollUp = (top: number, device?: string) => {
  if (device === "desktop" || device === "tablet") {
    window.scrollTo({
      top,
      behavior: "smooth",
    });
  } else {
    const count = 20;
    const id = global.setInterval(() => {
      if (document.documentElement.scrollTop <= top) {
        clearInterval(id);
      } else {
        document.documentElement.scrollTop =
          document.documentElement.scrollTop - count;
      }
    }, 1);
  }
};

const dateTimeFormatter = (date: string | undefined) => {
  if (date) {
    const dateObj = new Date(date);
    const dateTimeFormat = new Intl.DateTimeFormat("jp", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      // fractionalSecondDigits: 3,
      hour12: false,
      timeZone: "Asia/Tokyo",
    });

    const arr = dateTimeFormat.formatToParts(dateObj);

    const extract = (
      str: string,
      array: Array<Intl.DateTimeFormatPart> = arr
    ) => {
      return array.find((obj) => obj.type === str)?.value;
    };

    const year = extract("year");
    const month = extract("month");
    const day = extract("day");
    const hour = extract("hour");
    const min = extract("minute");

    const weekdayNum = dateObj.getDay();
    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
      weekdayNum
    ];

    return {
      year,
      month,
      day,
      hour,
      min,
      weekday,
    };
  } else {
    return { year: "", month: "", day: "" };
  }
};

const projectDateCalculation = (project: Project) => {
  const startDate = dateTimeFormatter(project.startDate);
  const endDate = dateTimeFormatter(project.endDate);
  const { year, month, day, hour, min, weekday } = startDate;

  let date = `${year}.${month}.${day} ${weekday}. ${hour}:${min}`;

  if (endDate.year && endDate.month && endDate.day) {
    date += "〜";

    if (
      !(year === endDate.year && month === endDate.month && day === endDate.day)
    ) {
      const { year, month, day, weekday } = endDate;
      date += `${year}.${month}.${day} ${weekday}. `;
    }

    if (endDate.hour && endDate.min) {
      date += `${endDate.hour}:${endDate.min}`;
    }
  }

  return date;
};

export {
  fontFamilySeparator,
  ptToRemConverter,
  ptToVwConverter,
  mobileVwConverter,
  convertDate,
  parseInfo,
  // swapId,
  filterId,
  scrollUp,
  dateTimeFormatter,
  projectDateCalculation,
};
