import { createMuiTheme } from "@material-ui/core/styles";
import { fontFamilySeparator } from "./utils";
// import { red } from "@material-ui/core/colors";

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#4d4d4d",
    },
    // error: {
    //   main: red.A400,
    // },
    background: {
      default: "#fff",
      paper: "#1a1a1a",
    },
    text: {
      primary: "#000",
      secondary: "#b3b3b3",
    },
  },
  typography: {
    fontFamily: fontFamilySeparator(
      [
        "Muli",
        "Yu Gothic",
        "游ゴシック",
        "YuGothic",
        "游ゴシック体",
        "ヒラギノ角ゴ Pro W3",
        "メイリオ",
        "sans - serif",
      ].join(",")
    ),
    fontSize: 16, // 16px = 12pt
  },
});

export default theme;
