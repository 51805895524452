export const options = [
  "news",
  "project",
  "performances",
  "workshops",
  "about",
  "members",
  "naoya aoki",
  "support",
];

export const socialMedia = [
  {
    name: "vimeo",
    width: 100,
    height: 16,
    footerHeight: 46,
    footerWidth: 95,
    link: "https://vimeo.com/user46238526",
  },
  {
    name: "facebook",
    height: 26,
    footerHeight: 26,
    link: "https://www.facebook.com/aokigwp2018",
  },
  {
    name: "twitter",
    height: 26,
    footerHeight: 26,
    link: "https://twitter.com/zero_aokigwp",
  },
  {
    name: "insta",
    height: 26,
    footerHeight: 26,
    link: "https://www.instagram.com/zero_aokigwp/",
  },
];

export const sitemap = [
  {
    title: "News",
    link: "/news",
    subMenus: [],
  },
  {
    title: "Project",
    link: "/project",
    subMenus: [],
  },
  {
    title: "Performances",
    link: "/performances",
    subMenus: [
      { title: "performances", link: "0" },
      { title: "videoWorks", link: "1" },
      { title: "outreach", link: "2" },
    ],
  },
  {
    title: "Workshops",
    link: "/workshops",
    subMenus: [
      { title: "events", link: "" },
      { title: "education", link: "" },
    ],
  },
  {
    title: "About",
    link: "/about",
    subMenus: [
      { title: "about", link: "" },
      { title: "overview", link: "" },
    ],
  },
  {
    title: "Members",
    link: "/members",
    subMenus: [
      { title: "members", link: "" },
      { title: "graduates", link: "" },
      { title: "artists", link: "" },
    ],
  },
  {
    title: "Naoya Aoki",
    link: "/naoya_aoki",
    subMenus: [
      { title: "profile", link: "" },
      { title: "activities", link: "" },
      { title: "choreography", link: "" },
      { title: "method", link: "" },
    ],
  },
  {
    title: "Support",
    link: "/support",
    subMenus: [],
  },
  {
    title: "Contact",
    link: "/contact",
    subMenus: [],
  },
];

export const langs = ["ja", "en"];
