import styled from "styled-components";
import theme from "../../theme";

import { ptToVwConverter, mobileVwConverter } from "../../utils";
import { Button, Grid } from "@material-ui/core";

// NavBar

export const NavBarContainer = styled(Grid)`
  && {
    width: 100%;
    background-color: #000;
    position: fixed;
    z-index: 1;
  }
`;

// White bar

export const PCWhiteBarContainer = styled(Grid)`
  && {
    background-color: ${theme.palette.background.default};
    height: ${ptToVwConverter(40)}vw;
    color: black;
  }
`;

// height: ${ptToVwConverter(40)}vw;
//  font-size: ${ptToVwConverter(14)}vw;
export const SubMenuContainer = styled.div`
  font-size: 14px;
  float: right;
`;

export const SNSButton = styled(Button)`
  && {
    height: ${ptToVwConverter(40)}vw;
    width: 28px;
    display: inline-flex;
    justify-contents: center;
    align-items: center;
    padding: 0;
  }
`;
// margin-right: ${ptToVwConverter(3)}vw;

// height: ${ptToVwConverter(18)}vw;
// objectfit: "contain";
export const SNSImage = styled.img``;

// width: ${ptToVwConverter(10)}vw;
// height: ${ptToVwConverter(40)}vw;
export const LangButton = styled(Button)`
  && {
    padding: 0;
    color: ${theme.palette.text.secondary};
    font-size: 14px;
  }
`;

// Menu

export const PCBlackContainer = styled.div`
  height: ${ptToVwConverter(80)}vw;
  display: flex;
  align-items: center;
`;

// width: ${ptToVwConverter(60)}vw;
// margin-right: ${ptToVwConverter(352)}vw;
export const IconContainer = styled.div`
  margin-left: ${ptToVwConverter(100)}vw;
`;

export const IconImage = styled.img`
  height: ${ptToVwConverter(35)}vw;
  padding-top: ${ptToVwConverter(5)}vw;
  opacity: 1;
`;

// font-size: ${ptToVwConverter(14)}vw;
// letter-spacing: ${ptToVwConverter(1.6)}vw;
// width: ${ptToVwConverter(144)}vw;
export const MenuButton = styled(Button)`
  && {
    height: ${ptToVwConverter(80)}vw;
    color: ${theme.palette.primary.main};
    font-size: 14px;
    letter-spacing: 1.6px;
    border-radius: 0;
    opacity: 1;
    margin-right: ${ptToVwConverter(20)}vw;
  }
`;
// height: ${ptToVwConverter(35)}vw;
// border-bottom: 1px solid ${theme.palette.primary.main} !important;
// transition: 0.3s;

export const ContactButtonContainer = styled.div`
  width: ${ptToVwConverter(80)}vw;
  display: inline-block;
`;

export const ContactButton = styled(Button)`
  && {
    height: ${ptToVwConverter(80)}vw;
    width: ${ptToVwConverter(80)}vw;
    color: white;
    background-color: #666;
    border-radius: 0;
    opacity: 1;
  }
`;

// Top

export const BlackContainer = styled(Grid)`
  && {
    background-color: ${theme.palette.background.paper};
  }
`;

// Top: Upcoming / News

// Font

export const NewsText = styled.div`
  color: ${theme.palette.text.secondary};
  font-size: 12px;
  letter-spacing: 0.1em;
`;

export const NewsSmallTitle = styled.div`
  color: ${theme.palette.primary.main};
  font-size: 14px;
  letter-spacing: 0.1em;
  margin: ${ptToVwConverter(8)}vw 0 ${ptToVwConverter(4)}vw 0;
`;

// font-size: ${ptToVwConverter(18)}vw;
export const NewsTitle = styled.div`
  color: ${theme.palette.primary.main};
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin: ${ptToVwConverter(16)}vw 0 ${ptToVwConverter(16)}vw 0;
`;

// custom HTML

export const UpcomingContainer = styled.div`
  margin: ${ptToVwConverter(16)}vw 0 ${ptToVwConverter(16)}vw 0;
  @media (max-width: 1024px) {
    margin-left: ${mobileVwConverter(50)}vw;
    margin-right: ${mobileVwConverter(50)}vw;
    width: 100%;
  }
`;

export const NewsContainer = styled.div`
  margin: ${ptToVwConverter(32)}vw 0 ${ptToVwConverter(16)}vw 0;
  @media (max-width: 1024px) {
    margin-left: ${mobileVwConverter(50)}vw;
    margin-right: ${mobileVwConverter(50)}vw;
    margin-bottom: 0;
    width: 100%;
  }
`;

export const NewsPhoto = styled.img`
  width: ${ptToVwConverter(250)}vw;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

// font-size: ${ptToVwConverter(14)}vw;
// letter-spacing: ${ptToVwConverter(1.6)}vw;
// width: ${ptToVwConverter(130)}vw;
export const MoreButton = styled(Button)`
  && {
    display: inline-block;
    padding: 9px 43px;
    font-size: 14px;
    letter-spacing: 1.6px;
    color: ${theme.palette.primary.main};
    background-color: #666;
    border-radius: 0;
    margin-top: ${ptToVwConverter(12)}vw;

    @media (max-width: 464px) {
      width: ${mobileVwConverter(320)}vw;
      height: ${mobileVwConverter(100)}vw;
      padding: 0;
      margin-top: ${mobileVwConverter(10)}vw;
      margin-bottom: ${mobileVwConverter(120)}vw;
    }
  }
`;

// Top About

// line-height: ${ptToVwConverter(32)}vw;
// height: ${ptToVwConverter(325)}vw;
export const AboutContainer = styled.div`
  margin-left: ${ptToVwConverter(200)}vw;
  margin-right: ${ptToVwConverter(150)}vw;
  margin-bottom: ${ptToVwConverter(70)}vw;
  padding-top: ${ptToVwConverter(75)}vw;
  line-height: 32px;
  @media (max-width: 1024px) {
    line-height: 36px;
    margin-left: ${mobileVwConverter(50)}vw;
    margin-bottom: 0;
  }
`;
// padding-top: 5rem;

// font-size: ${ptToVwConverter(14)}vw;
export const AboutText = styled.div`
  color: ${theme.palette.text.secondary};
  font-size: 14px;
  letter-spacing: 0.1em;
`;

// font-size: ${ptToVwConverter(14)}vw;
// letter-spacing: ${ptToVwConverter(1.6)}vw;
// width: ${ptToVwConverter(200)}vw;
// height: ${ptToVwConverter(50)}vw;
export const AboutButton = styled(Button)`
  && {
    display: inline-block;
    padding: 9px 43px;
    font-size: 14px;
    letter-spacing: 1.6px;
    color: ${theme.palette.primary.main};
    background-color: #666;
    border-radius: 0;
    margin-top: ${ptToVwConverter(30)}vw;
    text-transform: none;

    @media (max-width: 464px) {
      width: ${mobileVwConverter(320)}vw;
      height: ${mobileVwConverter(100)}vw;
      padding: 0;
      margin-top: ${mobileVwConverter(50)}vw;
      margin-bottom: ${mobileVwConverter(80)}vw;
    }
  }
`;

// Top Main
export const TopMainContainer = styled.div`
  height: ${ptToVwConverter(2100)}vw;
  margin-top: ${ptToVwConverter(60)}vw;
  padding-left: ${ptToVwConverter(174)}vw;
  padding-right: ${ptToVwConverter(200)}vw;
  @media (max-width: 1024px) {
    margin: ${mobileVwConverter(120)}vw ${mobileVwConverter(50)}vw 0
      ${mobileVwConverter(50)}vw;
    padding: 0;
    height: auto;
  }
`;

// Top Contents
export const TopContentsContainer = styled.div`
  margin: ${ptToVwConverter(90)}vw 0 ${ptToVwConverter(50)}vw 0;
  @media (min-width: 1024px) {
    display: flex;
  }
  @media (max-width: 1024px) {
    margin-bottom: ${mobileVwConverter(230)}vw;
  }
`;

export const TopContentsTitleContainer = styled.div`
  height: ${ptToVwConverter(500)}vw;
  width: ${ptToVwConverter(6)}vw;
  margin-left: ${ptToVwConverter(51)}vw;
`;

export const TopContentsTitle = styled.div`
  transform: rotate(90deg);
  transform-origin: top left;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  font-size: 25px;
  white-space: nowrap;
`;

// height: ${ptToVwConverter(50)}vw;
export const DecorationLine = styled.div`
  @media (min-width: 1024px) {
    height: 50px;
    border-right: solid ${ptToVwConverter(3)}vw black;
  }
  @media (max-width: 1024px) {
    height: 10px;
    width: 38px;
    border-bottom: solid ${mobileVwConverter(5)}vw black;
  }
`;

// Top Contents Right Container
export const TopContentsRightContainer = styled.main`
  @media (min-width: 1024px) {
    height: ${ptToVwConverter(475)}vw;
    margin-top: ${ptToVwConverter(25)}vw;
  }
  @media (max-width: 1024px) {
    padding-top: ${mobileVwConverter(50)}vw;
    padding-left: ${mobileVwConverter(50)}vw;
    height: auto;
  }
`;
export const TopOdlContainer = styled.div`
  @media (min-width: 1024px) {
    height: ${ptToVwConverter(425)}vw;
    width: 100%;
    margin-left: ${ptToVwConverter(26)}vw;
    display: flex;
  }
  @media (max-width: 1024px) {
    padding-right: ${mobileVwConverter(50)}vw;
  }
`;
export const TopContentsMain = styled.div`
  @media (min-width: 1024px) {
    height: ${ptToVwConverter(425)}vw;
    margin-left: ${ptToVwConverter(26)}vw;
    display: flex;
  }
`;
export const TopContentsButtonContainer = styled.div`
  margin-left: ${ptToVwConverter(965)}vw;
  height: ${ptToVwConverter(50)}vw;
  @media (max-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    margin: ${mobileVwConverter(10)}vw 0 0 0;
    height: auto;
  }
`;
export const TopContentsMoreButton = styled(Button)`
  && {
    width: ${ptToVwConverter(200)}vw;
    height: ${ptToVwConverter(50)}vw;
    font-size: 14px;
    background-color: black;
    color: ${theme.palette.primary.main};
    border-radius: 0;
    letter-spacing: 1.6px;
    &:hover {
      background-color: black;
    }
    @media (max-width: 1024px) {
      width: 160px;
      height: 50px;
    }
  }
`;

// Top Our daily life

export const TopSNSContainer = styled.div`
  width: ${ptToVwConverter(360)}vw;
  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: ${mobileVwConverter(70)}vw;
  }
`;

// font-size: ${ptToVwConverter(18)}vw;
export const TitleOdl = styled(Button)`
  && {
    display: flex;
    font-size: 18px;
    text-transform: none;
    letter-spacing: ${ptToVwConverter(1.6)}vw;
    margin-bottom: ${ptToVwConverter(10)}vw;
    padding-left: 0;
    @media (max-width: 1024px) {
      letter-spacing: 0.1rem;
    }
  }
`;

export const SNSOdl = styled.img`
  width: ${ptToVwConverter(30)}vw;
  height: ${ptToVwConverter(30)}vw;
  margin-right: ${ptToVwConverter(10)}vw;
  @media (max-width: 1024px) {
    width: ${mobileVwConverter(40)}vw;
    height: ${mobileVwConverter(40)}vw;
    margin-right: ${mobileVwConverter(10)}vw;
  }
`;

export const SNSContentsWrapper = styled.div`
  height: ${ptToVwConverter(670)}vw;
  border: solid ${ptToVwConverter(1)}vw #d4d4d4;
  @media (max-width: 464px) {
    height: ${mobileVwConverter(1010)}vw;
  }
`;

export const InstaImage = styled(Button)`
  && {
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0;
    width: 100%;
    height: 100%;
  }
`;

export const NameOdl = styled.div`
  padding-top: ${ptToVwConverter(3)}vw;
`;

// Top Event
export const TopEventContainer = styled.div`
  width: ${ptToVwConverter(360)}vw;
  @media (max-width: 1024px) {
    width: 100%;
    padding-right: ${mobileVwConverter(50)}vw;
    margin-bottom: ${mobileVwConverter(50)}vw;
  }
`;
export const EventButton = styled(Button)`
  && {
    padding: 0;
    text-align: unset;
    text-transform: unset;
    @media (max-width: 1024px) {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;
export const TopEventImage = styled.img`
  height: ${ptToVwConverter(240)}vw;
  border: solid 1px #d4d4d4;
  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
  }
`;
export const TopEventImageAlt = styled.div`
  height: ${ptToVwConverter(240)}vw;
  width: ${ptToVwConverter(360)}vw;
  border: solid 1px #d4d4d4;
`;
export const TopEventTitle = styled.div`
  margin-top: ${ptToVwConverter(20)}vw;
  margin-bottom: ${ptToVwConverter(16)}vw;
  font-size: 16px;
  letter-spacing: 1.6px;
  line-height: 24px;
  color: #000;
`;
export const TopEventLocation = styled.div`
  font-size: 12px;
  color: #808080;
`;
export const TopEventSponsor = styled.div`
  font-size: 12px;
  color: #808080;
  line-height: 32px;
`;

// Footer

// margin-right: ${ptToVwConverter(7)}vw;
// height: ${ptToVwConverter(40)}vw;
// min-width: ${ptToVwConverter(28)}vw;
export const SNSFooterButton = styled(Button)`
  && {
    width: ${ptToVwConverter(28)}vw;
    @media (max-width: 464px) {
      min-width: ${mobileVwConverter(100)}vw;
      width: ${mobileVwConverter(100)}vw;
    }
  }
`;

export const LinkButton = styled(Button)`
  && {
    padding: 0;
    display: block;
    text-align: left;
    line-height: unset;
    text-transform: unset;
    border-radius: 0;
    @media (max-width: 1024px) {
      width: 100%;
      padding-top: ${mobileVwConverter(40)}vw;
      padding-bottom: ${mobileVwConverter(40)}vw;
    }
  }
`;
