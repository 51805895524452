import { useState, useEffect } from "react";

export const getBreakPoint = (windowWidth: number) => {
  if (windowWidth < 464) {
    return "mobile";
  } else if (windowWidth < 1024) {
    return "tablet";
  } else if (windowWidth >= 1024) {
    return "desktop";
  }
};

export default () => {
  const isWindowClient = typeof window === "object";

  const [windowSize, setWindowSize] = useState(
    isWindowClient ? getBreakPoint(window.innerWidth) : undefined
  );

  useEffect(() => {
    function setSize() {
      setWindowSize(getBreakPoint(window.innerWidth));
    }

    // const selectiveSetSize = () => {
    //   if (windowSize === "mobile") {
    //     return;
    //   }
    //   setSize();
    // };

    if (isWindowClient) {
      window.addEventListener("resize", setSize, { passive: true });

      return () => window.removeEventListener("resize", setSize);
    }
  }, []);

  return windowSize;
};
